.Hero {
  box-shadow: inset 0 0 5px $black;
  padding: 100px 0;
  background-image: linear-gradient(135deg, $secondary, $primary);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 50% 50%;
  background-size: cover;
  color: $white;
  text-shadow: 0 0 5px $blueGrey;
  position: relative;
}

.Hero__title {
  margin-bottom: 30px;

  @include bp(sm, 'max') {
    font-size: 50px;
  }
}

.Hero__link {
  color: $white;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
    color: inherit;
  }
}

.Nav {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  flex-direction: column;
  text-align: right;

  @include bp(md, 'max') {
    display: none;
  }
}

.Nav__list {
  padding: 0;
  margin-bottom: 0;
  background-color: $primary;
  border-bottom: 2px solid $white;
}

.Nav__item {
  list-style: none;
  display: inline-block;
  width: 100%;
  text-align: left;
  border-bottom: 1px solid rgba(185, 185, 185, 0.31);
  font-weight: bold;

  @include bp(md) {
    margin-left: 10px;
    width: auto;
    font-size: 18px;
    font-weight: 400;
  }

  ul {
    list-style: none;

    @include bp(md) {
      display: none;
    }

    li {
      font-weight: 400;
    }
  }
}

.Nav__link {
  padding: 10px 15px;
  display: block;
  box-shadow: 0 0 10px -5px $primary;
  transition: box-shadow .2s ease-in-out, padding-top .2s ease-in-out;
  will-change: box-shadow, padding-top;
  color: $white;

  &:hover {
    color: $white;
  }
}

.MenuButton {
  background: none;
  border: 0;
  float: right;
  overflow: hidden;
  cursor: pointer;
  top: 12px;
  position: relative;
}

.MenuButton__line-1,
.MenuButton__line-2,
.MenuButton__line-3 {
  opacity: 1;
  top: 0;
  transform: translateX(0);
  transition: opacity .3s, transform .5s, top .5s;
  will-change: opacity, transform, top;
  width: 45px;
  height: 3px;
  background-color: $primary;
  display: block;
  margin-bottom: 10px;
  position: relative;
}

.MenuButton--open {
  .MenuButton__line-1 {
    transform: rotateZ(45deg);
    top: 13px;
  }

  .MenuButton__line-2 {
    opacity: 0;
    transform: translateX(20px);
  }

  .MenuButton__line-3 {
    transform: rotateZ(-45deg);
    top: -13px;
  }
}

.Breadcrumb {
  background-color: $gray-200;
  border-radius: .5rem;
  margin-top: .5rem;
  padding: 0 .5rem;
  display: inline-block;
  border: 1px solid $gray-500;
}

.Breadcrumb__block {
  display: inline-block;
  padding: .25rem 0;
  margin: 0;
  font-weight: 500;
  font-size: 14px;
}

.Breadcrumb__block--current {
  background-color: unset;
}

.Breadcrumb__spacer {
  display: inline-block;
  padding: .25rem .5rem;
  font-size: 15px;
}

a {
  color: $secondary;
}

img {
  max-width: 100%;
}

.background--grey {
  background-color: $gray-200;
}

.background--primary {
  background-color: $primary;
}

.background--secondary {
  background-color: $secondary;
}

.error {
  color: red;
}

.Header {
  box-shadow: 0 0 10px $primary;
  padding: 5px 0 0;
  position: relative;
}

.Header__logo {
  max-width: 100%;
  padding-bottom: 5px;
}

.Footer {
  color: $white;
  padding: 20px 0;
  background: $blueGrey;
  box-shadow: inset 0 0 20px $black;
}

.Footer__social {
  margin: 0 5px;

  &:hover {
    text-decoration: none;
  }
}
